import { useEffect, useRef, useState } from 'react';
import { Console } from 'console-feed';
import io from 'socket.io-client';

const SOCKET_HOST = 'https://overlay-sockets.lumiastream.com';
let socket: any;

enum ConnectionStatus {
	DISCONNECTED,
	LOADING,
	CONNECTED,
	RECONNECTING,
}

function App() {
	const [room, setRoom] = useState<string>();
	const [status, setStatus] = useState(ConnectionStatus.DISCONNECTED);
	const [roomType, setRoomType] = useState('debug');
	const [logs, setLogs] = useState([]);
	const [autoScroll, setAutoScroll] = useState(true);
	const logObserver = useRef<MutationObserver>();
	const logList = useRef<HTMLDivElement>();

	useEffect(() => {
		logObserver.current = new MutationObserver(scrollToBottom);
		logObserver.current.observe(logList.current, {
			childList: true,
			subtree: true,
		});

		return () => {
			logObserver.current.disconnect();
		};
	}, [autoScroll]);

	useEffect(() => {
		return () => {
			if (socket) socket.disconnect();
		};
	}, []);

	const scrollToBottom = () => {
		if (autoScroll) {
			logList.current.scrollTop = logList.current.scrollHeight;
		}
	};

	const connect = () => {
		console.log('connecting');
		setStatus(ConnectionStatus.LOADING);
		socket = io(SOCKET_HOST, { transports: ['websocket'] });
		const fullRoom = `${room?.toLowerCase()}-${roomType}`;
		console.log('fullRoom: ', fullRoom);
		socket.on('connect', () => {
			console.log('Connected to socket');
			socket.emit('connected', { name: 'lumiaDebugger' });
			socket.emit('room', { room: fullRoom, secret: 'LumiaOverRooms' });
			setStatus(ConnectionStatus.CONNECTED);
		});
		socket.on('disconnect', (reason) => {
			console.log('Disconnected from socket', reason);
			setStatus(ConnectionStatus.DISCONNECTED);
		});
		socket.on('log', (level, log) => {
			console.log('New Log: ', { method: level || 'info', data: [log] });
			setLogs((oldLogs) => [
				...oldLogs,
				{ method: level || 'info', data: [log] },
			]);
		});
	};

	const update = (e) => {
		setRoom(e.target.value);
	};

	const selectRoomType = (e) => {
		console.log('selectRoomType: ', e.target.value);
		setRoomType(e.target.value);
	};

	return (
		<div
			className="App"
			style={{
				overflow: 'hidden',
				padding: '40px',
				height: '100vh',
				color: 'white',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-start',
				fontSize: '2rem',
				backgroundColor: '#19171c',
			}}
		>
			<div style={{ display: 'flex' }}>
				<div>
					<label
						style={{
							fontSize: '18px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						Room
						<input
							type="text"
							style={{
								marginRight: '40px',
								color: '#777',
								borderColor: 'green',
							}}
							id="room"
							value={room}
							onChange={update}
							color="primary"
						/>
					</label>
				</div>
				<div style={{ marginRight: '40px' }}>
					<label style={{ fontSize: '12px' }}>
						<input
							type="checkbox"
							checked={roomType === 'debug'}
							value={'debug'}
							onChange={selectRoomType}
							name="debug"
						/>
						Debug
					</label>
					<label style={{ fontSize: '12px' }}>
						<input
							type="checkbox"
							checked={roomType === 'discover'}
							value={'discover'}
							onChange={selectRoomType}
							name="discover"
						/>
						Discover
					</label>
				</div>
				<div>
					<button
						style={{
							color:
								status === ConnectionStatus.CONNECTED
									? 'default'
									: status === ConnectionStatus.DISCONNECTED
									? 'primary'
									: status === ConnectionStatus.LOADING
									? 'secondary'
									: 'secondary',
						}}
						onClick={connect}
					>
						{status === ConnectionStatus.CONNECTED
							? 'Connected'
							: status === ConnectionStatus.DISCONNECTED
							? 'Start'
							: status === ConnectionStatus.LOADING
							? 'Loading'
							: 'Reconnecting'}
					</button>
				</div>
			</div>
			<div style={{ margin: '20px 0' }}>
				<button onClick={() => setAutoScroll(!autoScroll)}>
					{autoScroll ? 'Stop' : 'Start'} Auto Scroll
				</button>
			</div>
			<div
				ref={logList}
				style={{
					textAlign: 'left',
					marginTop: '40px',
					width: '90%',
					height: '80vh',
					border: 'solid 1px white',
					overflowY: 'auto',
				}}
			>
				<Console logs={logs} variant="dark" />
			</div>
		</div>
	);
}

export default App;
